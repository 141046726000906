import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../Layout/Layout';
import { Seo } from '../../components';
import { ArticleProps } from '../../hooks/api/useSectionArticlesQuery';
import { SectionSimilarNews, SectionSinglePost } from '../sections';

interface BlogPostProps {
  data: {
    allContentfulArticle: {
      nodes: Array<ArticleProps>;
    };
  };
  location: Location;
}

const BlogPost = ({ location, data }: BlogPostProps) => {
  const { title, content, createdAt, mainImage, slug } = data.allContentfulArticle.nodes[0];
  return (
    <Layout location={location}>
      <Seo />
      <SectionSinglePost
        content={content}
        createdAt={createdAt}
        mainImage={mainImage}
        title={title}
      />
      <SectionSimilarNews currentArticleSlug={slug} />
    </Layout>
  );
};

export default BlogPost;

export const query = graphql`
  query ($slug: String!) {
    allContentfulArticle(filter: { slug: { eq: $slug } }) {
      nodes {
        title
        content {
          raw
        }
        slug
        createdAt
        mainImage {
          file {
            url
            fileName
          }
        }
      }
    }
  }
`;
